<template>
  <div class="text-left SignUpSignIn">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-7 MainSection">
        <div class="">
          <router-link to="/">
            <img
              src="/assets/img/new-website/LogoQuizell.svg"
              style="height: 40px; cursor: pointer"
              alt="Quizell Product Recommendation Quiz Logo"
              title="Product recommendation quiz"
              class="img-fluid"
              height="40"
              width="180"
            />
          </router-link>
        </div>
        <div
          class="d-flex flex-column justify-content-center align-items-center"
          style="min-height: 90%"
        >
          <div
            class="h-100 d-flex align-items-center justify-content-center flex-grow-1"
          >
            <div class="verifyDiv text-center">
              <h1
                class="text-center verifyTitle"
                style="
                  font-family: 'Poppins';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 30px;
                  line-height: 36px;
                  color: #58217f;
                "
              >
                Verify your email
              </h1>
              <h2
                class="text-center verifySubText"
                style="
                  font-family: 'Poppins';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 24px;
                  line-height: 36px;
                  color: #000000;
                "
              >
                We sent an verification email. Check your inbox to activate your
                account!
              </h2>

              <button
                class="btn mt-3 ResendEmailBtn"
                style="background: #4d1b7e;border-radius: 10px;font-family: `Poppins`;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;"
                @click="generateResendEmail"
              >
                <span v-if="!resendEmailSpinner"
                  >Resend verification email</span
                >
                <div
                  v-else
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-none d-lg-block col-lg-5 objectSection lazyload"
        :data-bg="'~/assets/new-website/SignUpNewBg.svg'"
      >
        <LazyImage
          src="https://images.quizell.com/website/SignUpNewBg.svg"
          :title="`Quizell login`"
          :alt="`Quizell login`"
          style="height: 100%; width: 100%"
          :loading="'lazy'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      AuthUser: {
        email: "test",
      },
      resendEmailSpinner: false,
    };
  },
  methods: {
    async generateResendEmail() {
      this.resendEmailSpinner = true;
      try {
        const response = await axios.get(`/resend/verify`);
        if (response.status == 200 && response.data.status == "fail") {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
        } else if (
          response.status == 200 &&
          response.data.status == "success"
        ) {
          this.$toasted.show(`Email sent again successfully!`, {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
        }
        throw error;
      } finally {
        this.resendEmailSpinner = false;
      }
    },
    async getUser() {
      if (process.client) {
        let user_access_token = localStorage.getItem("user_access_token");
        if (user_access_token !== null) {
          try {
            const response = await this.$axios.get(`/getCurrentUser`);
            if (response.status == 200) {
              // this.User = response.data.data
            }
          } catch (error) {
            if (error.response.status == 401) {
              console.log("Verify Email Error", error);
            }
          }
        }
      }
    },
  },
  created() {
    this.getUser();
  },
};
</script>

<style scoped>
.SignUpSignIn {
  overflow-x: hidden;
}
.SignUpSignIn .MainSection {
  padding: 1% 5%;
  /* height:100%; */
  min-height: 100vh;
  overflow-y: scroll;
  background: #ffffff;
}

.objectSection {
  background-color: #f6edff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ResendEmailBtn {
  background: #4d1b7e;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}
.ResendEmailBtn:hover {
  background: #ffc000;
  color: #ffffff;
}
</style>
